<template>
	<div class="h-full">
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange"
		 :queryParam="queryParam">
			<div slot="buttonGroup" class="h-handle-button">
                <div class="h-b">
                    <el-button type="primary" size="small" @click="add" v-right-code="'Gspcomplaint:Save'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="cancel" v-right-code="'Gspcomplaint:Cancel'">作废</el-button>
                </div>
            </div>
			<p slot="elList">
				<el-table ref="stockinfoListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange"
				 highlight-current-row>
					<el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
					<el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" :key="index" :prop="col.FieldName"
					 :label="col.DisplayName" :render-header="bindFilter(queryParam,col,ignoreField)" :fixed="index==0&&(!config||!config.isDetailDisplay)"
					 :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0" v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
						<template slot-scope="scope">
                            <span v-bind:style="{ color: scope.row.ExceptionCount>0?'red':'#1874CD' }"
                                v-if="col.FieldName==='GspRecordNo'" type="text" @click="rowClick(scope)"
                                style="text-decoration:underline;cursor:pointer">{{ scope.row[col.FieldName] }}</span>
                            <span v-else> {{ scope.row[col.FieldName] }}</span>
                        </template>
					</el-table-column>
				</el-table>
			</p>
		</fixed-list>
	</div>
</template>
<script>
	export default {
		mounted() {
			this.Utils.lazy(() => {
				this.Event.$on("reloadPageList", () => this.reloadPageList());
			});
			var _this = this;
			this.initialize();
		},
		data() {
			return {
				ignoreField: [],
				multipleSelection: [],
				queryParam: {
					PageIndex: 1,
					PageSize: 10,
					Params:{
					},
                    ViewStatus:1,
                    TabStatus: 0,
                    TabShow:false,
                    TabItems: [],
				},
				dataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0
				}
			}
		},
		props: {
			config: {
				isDetailDisplay: false
			},
			option: {}
		},
		methods: {
			reloadPageList: function() {
				this.initialize();
			},
			initialize() {
				this.onPageChange(this.queryParam);
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.$emit("onCheckRow", this.multipleSelection);
			},
			onPageChange(param) {
				var _this = this;
				this.queryParam = param;
				this.$ajax.query("omsapi/gspcomplaint/getlist", "post", param, data => {
					_this.dataSource = data;
                    _this.queryParam.TabItems = data.TabItems;
				});
			},
			rowClick: function (scope) {
                this.onChangeEditDataSource(scope.row.Id);
            },
			onDataSourceChange(ds) {
				var _this = this;

				_this.dataSource = {
					ColDefs: {
						BodyFieldParams: []
					},
					Result: [],
					TotalCount: 0
				};
				_this.$nextTick(function() {
					_this.dataSource = ds;

				});
			},
			onChangeEditDataSource(data) {
				this.config.isDetailDisplay = true;
				this.$emit("onChangeEditDataSource", data);
			},
			add() {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", null);
            },
			cancel:function(){
				if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择单据.`, "error");
                    return false;
                }

                var isValid = true;
                this.multipleSelection.forEach(item => (item.GspRecordStatus!=200) && (isValid = false));
                if(!isValid){
                    this.Utils.messageBox(`未作废单据才允许作废.`, "error");
                    return false;
                }

                var _this = this;
				var ids = _this.multipleSelection.map(item => item.Id);
                _this.Utils.confirm({
                    title:"作废确认",
                    content:"作废后数据不可恢复,是否确认作废?"
                },()=>{
                    this.$ajax.send("omsapi/gspcomplaint/cancel", "post", ids, (data) => {
                       _this.initialize();
                       _this.Utils.messageBox("作废成功.","success");
                    });
                },()=>{
                    _this.Utils.messageBox("已取消作废.","info");
                });
			}
		},
		components: {
		}
	}
</script>
